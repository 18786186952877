import { tokenUtil } from "@acclaim/udaf-kit";
import {
  ADMIN_BASE_URL,
  API_BASE_URL,
  RECOGNIZE_URL,
  RENEWAL_STATUSES,
  INSPECTIONS_BASE_URL,
} from "common/constants";
import queryString from "query-string";
import axios from "axios";

export const FORCE_REFRESH = true;
export const ACCESS_TOKEN = "accessToken";
const CURRENT_USER_SESSION = "currentUser";

export const FEATURE_FAVORITES = false;

export const _empty = (array) => array && array.length === 0;

export const ORDER_STATUSES = {
  CREATED: "In Cart", // just created
  CANCELED: "Canceled", // canceled by the user
  CAPTURED: "Paid Pending Review", // capture authorized through GovPay
  REVERSED: "Order Failed", // capture reversed through GovPay
  HIDDEN: "Hidden", // capture reversed through GovPay
  APPROVED: "Approved", // the order has been approved by staff
  REFUNDED: "Refunded", // the order was refunded by staff
};

export const PRODUCTS = {
  BRAND_RENEWAL: "Renewal",
  NEW_BRAND: "Registration",
  BRAND_TRANSFER: "Transfer",
  BRAND_RENEWAL_CHEAP: "Renewal",
};

export const COUNTIES = [
  "Beaver",
  "Box Elder",
  "Cache",
  "Carbon",
  "Daggett",
  "Davis",
  "Duchesne",
  "Emery",
  "Garfield",
  "Grand",
  "Iron",
  "Juab",
  "Kane",
  "Millard",
  "Morgan",
  "Piute",
  "Rich",
  "Salt Lake",
  "San Juan",
  "Sanpete",
  "Sevier",
  "Summit",
  "Tooele",
  "Uintah",
  "Utah",
  "Wasatch",
  "Washington",
  "Wayne",
  "Weber",
  "Out-of-State",
];

export function logout() {
  tokenUtil.removeToken();
  sessionStorage.removeItem(CURRENT_USER_SESSION);
  clearAllBrandCaches();
}

export const getAdminUrl = () => {
  return (
    ADMIN_BASE_URL + "/login?id=" + encodeURIComponent(tokenUtil.getToken())
  );
};

export const getInspectionUrl = () => {
  return (
    INSPECTIONS_BASE_URL +
    "/login?id=" +
    encodeURIComponent(tokenUtil.getToken())
  );
};

const getOptionsWithAccessTokenFor = (contentType, options) => {
  const headers = new Headers({ "Content-Type": contentType });

  const accessToken = tokenUtil.getToken();

  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  const defaults = { headers: headers };
  return Object.assign({}, defaults, options);
};

const request = (options) => {
  options = getOptionsWithAccessTokenFor("application/json", options);
  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!tokenUtil.getToken()) {
    return Promise.reject("No access token set.");
  }

  const userFromSession = sessionStorage.getItem(CURRENT_USER_SESSION);
  if (userFromSession) {
    return Promise.resolve(JSON.parse(userFromSession));
  }

  return request({
    url: API_BASE_URL + "/user/me",
    method: "GET",
  }).then((returnedUser) => {
    sessionStorage.setItem(CURRENT_USER_SESSION, JSON.stringify(returnedUser));
    return returnedUser;
  });
}

export function getPin(brandId) {
  return request({
    url: `${API_BASE_URL}/admin/brands/${brandId}/pin`,
    method: "GET",
  });
}

export function getPinLink(brandId) {
  return request({
    url: `${API_BASE_URL}/admin/brands/${brandId}/pinLink`,
    method: "GET",
  });
}

export function getRenewal(brandHash, pinHash, querystring) {
  return request({
    url: `${API_BASE_URL}/renewals/${brandHash}/${pinHash}` + querystring,
    method: "GET",
  });
}

export function updateRenewToCanceledAtGovpay(renewalId) {
  return updateRenewalStatus(
    renewalId,
    RENEWAL_STATUSES.CANCELED_ON_PAY_SCREEN
  );
}

export function updateRenewalStatus(renewalId, newStatus) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}`,
    method: "PUT",
    body: JSON.stringify(newStatus),
  });
}

export function approveRenewalBrand(renewalId, brandId) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/brands/${brandId}`,
    method: "PUT",
  });
}

export function confirmRenewalUser(renewalId, userId) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/users/${userId}`,
    method: "PUT",
  });
}

export function renewalContinueToPay(renewalId) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/payments`,
    method: "POST",
  });
}

export function confirmRenewalCheckoutSuccess(renewalId, paymentProviderToken) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/payments/${paymentProviderToken}`,
    method: "PUT",
  });
}

export function confirmCheckoutSuccess(userId, orderId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/orders/${orderId}/receipt`,
    method: "PUT",
  });
}

export function approveRenewalOwners(renewalId, selectedOwner, updatedOwners) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/owners/${selectedOwner.lookupToken}`,
    method: "PUT",
    body: JSON.stringify(updatedOwners),
  });
}

export function getAppRenewalUrl(pinLink) {
  return `/${pinLink.brandHash}/${pinLink.pinHash}`;
}

export function addNote(brandId, note) {
  return request({
    url: API_BASE_URL + `/admin/brands/${brandId}/notes`,
    method: "POST",
    body: JSON.stringify(note),
  });
}

export function getNotes(brandId) {
  return request({
    url: `${API_BASE_URL}/admin/brands/${brandId}/notes`,
    method: "GET",
  });
}

export function hasAccount(user) {
  return user && user.id;
}

export function isAdmin(user) {
  const cUser = getCurrentUser();
  // console.log("current user:", cUser);

  return !!user && !!user.roles.find((role) => role.role === "Admin");
}
// BEGIN -- Admin services
export function isAdminEdit(user) {
  // const cUser = getCurrentUser();
  return !!user && !!user.roles.find((role) => role.role === "Admin Edit");
}
// END -- Admin services

export function auth(email, password, name) {
  // if a name is provided we assume this is a signup
  const url = API_BASE_URL + (name ? "/auth/signup" : "/auth/login");
  const objectToPass = name ? { name, email, password } : { email, password };
  return request({
    url: url,
    method: "POST",
    body: JSON.stringify(objectToPass),
  });
}

export function confirmReset(tokenQuerystring) {
  return request({
    url: API_BASE_URL + "/auth/confirm-reset" + tokenQuerystring,
    method: "GET",
  });
}

export function updatePassword(newPassword) {
  return request({
    url: API_BASE_URL + "/auth/reset-password",
    method: "PUT",
    body: JSON.stringify({ password: newPassword }),
  });
}

export function forgotPassword(email, previousUrl) {
  return request({
    url: API_BASE_URL + "/auth/forgot-password",
    method: "POST",
    body: JSON.stringify({ email, previousUrl }),
  });
}

export function registerNewPhone(userId, phoneRequest) {
  return request({
    url: API_BASE_URL + `/users/${userId}/phonenumbers`,
    method: "POST",
    body: JSON.stringify(phoneRequest),
  });
}

export function getPhoneNumbers(userId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/phonenumbers`,
    method: "GET",
  });
}

export function updatePhoneNumber(userId, phone) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/phonenumbers/${phone.id}`,
    method: "PUT",
    body: JSON.stringify(phone),
  });
}

export function removePhoneNumber(userId, phoneId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/phonenumbers/${phoneId}`,
    method: "DELETE",
  });
}

export function addAddress(userId, addressRequest) {
  return request({
    url: API_BASE_URL + `/users/${userId}/addresses`,
    method: "POST",
    body: JSON.stringify(addressRequest),
  });
}

export function getAddresses(userId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/addresses`,
    method: "GET",
  });
}

export function updateAddress(userId, address) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/addresses/${address.id}`,
    method: "PUT",
    body: JSON.stringify(address),
  });
}

export function removeAddress(userId, addressId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/addresses/${addressId}`,
    method: "DELETE",
  });
}

export function getImagesBy(query, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 20;
  return request({
    url: `${API_BASE_URL}/images/?query=${query}&page=${page}&size=${size}`,
    method: "GET",
  });
}

function createRecognizeClient() {
  const recognizeApi = axios.create({ baseURL: RECOGNIZE_URL, timeout: 60000 });

  recognizeApi.interceptors.response.use(null, (err) => {
    const { response } = err;
    console.error(response);
    return Promise.reject(err);
  });

  return recognizeApi;
}

export function getLettersFromDrawing(image) {
  const formData = new FormData();
  formData.append("image", image);
  return createRecognizeClient().post("/api/v1/animal/", formData);
  // return request({
  //     mode: 'no-cors',
  //     method: 'POST',
  //     formData
  // });
}

const LAST_PATHNAME_AND_STATE = "lastPathnameAndState";
export function leavingSiteFrom(location, state, componentId) {
  const { pathname, search } = location;
  const lastPath = {
    pathname,
    search,
    hash: componentId ? "#" + componentId : location.hash,
    state: state,
  };
  sessionStorage.setItem(LAST_PATHNAME_AND_STATE, JSON.stringify(lastPath));
}

export function getLastLocationAndState() {
  const lastKnownPathnameAndState = sessionStorage.getItem(
    LAST_PATHNAME_AND_STATE
  );
  sessionStorage.removeItem(LAST_PATHNAME_AND_STATE);
  return lastKnownPathnameAndState
    ? JSON.parse(lastKnownPathnameAndState)
    : { pathname: "/brands/search" };
}

export function getCart(userId) {
  return request({
    url: API_BASE_URL + `/users/${userId}/cart`,
    method: "GET",
  });
}

export function addToOrder(userId, brandId) {
  return request({
    url: API_BASE_URL + `/users/${userId}/orders`,
    method: "POST",
    body: `{"brandId":"${brandId}"}`,
  });
}

export function removeItemFromOrder(orderId, lineItemId) {
  return request({
    url: API_BASE_URL + `/orders/${orderId}/items/${lineItemId}`,
    method: "DELETE",
  });
}

// this function returns false when the order has an item!
export function hasItems(order) {
  return !(order && order.items && order.items.length > 0);
}

export function updateNote(note, brandId) {
  return request({
    url: API_BASE_URL + `/admin/brands/${brandId}/notes`,
    method: "PUT",
    body: JSON.stringify(note),
  });
}

export function removeNote(brandId, brandNoteId) {
  return request({
    url: API_BASE_URL + `/admin/brands/${brandId}/notes/${brandNoteId}`,
    method: "DELETE",
  });
}

export function editNote(userId, brandId) {
  return request({
    url: API_BASE_URL + `/users/${userId}/notes`,
    method: "POST",
    body: `{"brandId":"${brandId}"}`,
  });
}

export function pay(userId) {
  return request({
    url: API_BASE_URL + `/users/${userId}/pay`,
    method: "POST",
  });
}

export const ACTION_RENEW = "renew";

export function handleGovPayCancel() {
  // const {state} = lastLocationAndState;
  // if (state) {
  //     const {action, brandId} = state;
  //     if (ACTION_RENEW === action) {
  //         cancelRenew(state);
  //         clearCachedBrandsFor(brandId);
  //     }
  // }
  return getLastLocationAndState();
}

export function clearCachedBrandsFor(brandId) {
  clearCachedQueriesContaining(brandId);
  clearCachedMyBrandsContaining(brandId);
}

function foundCachedBrand(brandArray, brandIdToFind) {
  return brandArray.find((brand) => brand.id === brandIdToFind);
}

function clearCachedMyBrandsContaining(brandId) {
  const myCachedBrands = getMyCachedBrands();
  if (myCachedBrands && foundCachedBrand(myCachedBrands, brandId)) {
    removeMyBrandsFromSession();
  }
}

function removeMyBrandsFromSession() {
  sessionStorage.removeItem(MY_BRANDS_FROM_SESSION);
}

function clearCachedQueriesContaining(brandId) {
  getCachedSearchKeys().forEach((querystring) => {
    const cachedQueryResults = retrieveCacheBrandsForQuery(querystring);
    if (
      cachedQueryResults &&
      foundCachedBrand(cachedQueryResults.content, brandId)
    ) {
      clearCachedQuerystring(querystring);
    }
  });
}

export function clearAllBrandCaches() {
  removeMyBrandsFromSession();
  clearAllCachedSearchQueries();
}

function clearAllCachedSearchQueries() {
  getCachedSearchKeys().forEach((querystring) => {
    removeCachedSearchQuery(querystring);
  });
  sessionStorage.removeItem(CACHED_SEARCH_KEYS);
}

const CACHED_SEARCH_KEYS = "cachedQueryKeys";
export function clearCachedQuerystring(queryStringToRemove) {
  updateCachedSearchKeys(
    getCachedSearchKeys().filter((key) => key !== queryStringToRemove)
  );
  removeCachedSearchQuery(queryStringToRemove);
}

function removeCachedSearchQuery(queryStringToRemove) {
  sessionStorage.removeItem(queryStringToRemove);
}

export function retrieveCacheBrandsForQuery(querystring) {
  const foundBrandsFromSession = window.sessionStorage.getItem(querystring);
  if (foundBrandsFromSession) {
    // console.log("retrieved brands from cache", JSON.parse(foundBrandsFromSession));
    return JSON.parse(foundBrandsFromSession);
  }
  return null;
}

export function getQueryFrom(search) {
  return queryString.parse(search).query;
}

export function toQueryString(terms) {
  return "?" + queryString.stringify({ query: terms });
}

export function getSearchQuerystring(terms, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 10;
  const query = encodeURIComponent(terms.toLowerCase());
  return `query=${query}&page=${page}&size=${size}`;
}

export function getCertificateUrl(code) {
  return `/certificates/${code}`;
}

export function getWalletCardUrl(code) {
  return `/cards/${code}`;
}

export function getCertificate(code) {
  return request({
    url: `${API_BASE_URL}` + getCertificateUrl(code),
    method: "GET",
  });
}

export function getWalletCertificate(code) {
  return request({
    url: `${API_BASE_URL}` + getWalletCardUrl(code),
    method: "GET",
  });
}

export function searchBrands(terms, passedPage, passedSize) {
  const querystring = getSearchQuerystring(terms, passedPage, passedSize);

  const foundBrandsFromSession = retrieveCacheBrandsForQuery(querystring);

  if (foundBrandsFromSession) {
    return Promise.resolve(foundBrandsFromSession);
  }

  return request({
    url: `${API_BASE_URL}/brands/?${querystring}`,
    method: "GET",
  }).then((returnedBrands) => {
    // console.log("returned brands from server", returnedBrands);
    addCachedSearch(querystring, returnedBrands);
    return returnedBrands;
  });
}

function getCachedSearchKeys() {
  const currentlyCachedKeys = sessionStorage.getItem(CACHED_SEARCH_KEYS);
  return currentlyCachedKeys ? JSON.parse(currentlyCachedKeys) : [];
}

function addCachedSearch(newQueryString, returnedBrands) {
  updateCachedSearchKeys([...getCachedSearchKeys(), newQueryString]);
  sessionStorage.setItem(newQueryString, JSON.stringify(returnedBrands));
}

function updateCachedSearchKeys(newKeys) {
  // console.log("adding to cache search keys "+CACHED_SEARCH_KEYS, newKeys);
  sessionStorage.setItem(CACHED_SEARCH_KEYS, JSON.stringify(newKeys));
}

const MY_BRANDS_FROM_SESSION = "myBrands";
export function getMyBrands(userId, forceRefresh = false) {
  if (!forceRefresh) {
    const myBrandsFromSession = getMyCachedBrands();
    if (myBrandsFromSession) {
      return Promise.resolve(myBrandsFromSession);
    }
  }

  return request({
    url: `${API_BASE_URL}/users/${userId}/brands`,
    method: "GET",
  }).then((returnedBrands) => {
    sessionStorage.setItem(
      MY_BRANDS_FROM_SESSION,
      JSON.stringify(returnedBrands)
    );
    return returnedBrands;
  });
}

function getMyCachedBrands() {
  const myBrandsFromSession = sessionStorage.getItem(MY_BRANDS_FROM_SESSION);
  if (myBrandsFromSession) {
    return JSON.parse(myBrandsFromSession);
  }
  return null;
}

export function linkBrand(ownerId, pin) {
  return request({
    url: API_BASE_URL + `/owners/${ownerId}/link`,
    method: "PUT",
    body: pin,
  });
}

export function updateOwner(owner) {
  return request({
    url: API_BASE_URL + `/owners/${owner.id}`,
    method: "PUT",
    body: JSON.stringify(owner),
  });
}

export function replaceAt(oldArray, newEntry) {
  const newArray = oldArray.slice(0);
  const index = newArray.findIndex((oldEntry) => oldEntry.id === newEntry.id);
  newArray[index] = newEntry;
  return newArray;
}

export function getOrders(userId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/orders`,
    method: "GET",
  });
}

export function getProducts() {
  return request({
    url: `${API_BASE_URL}/orders/getProducts`,
    method: "GET",
  });
}

export function approveRenewalPeriodSelection(
  renewalId,
  orderId,
  productDetail
) {
  return request({
    url: `${API_BASE_URL}/renewals/${renewalId}/order/${orderId}`,
    method: "PUT",
    body: JSON.stringify(productDetail),
  });
}
// The request adds the bearer token for the current user
export function getBrandRenewalLink(userId, brandId) {
  return request({
    url: `${API_BASE_URL}/users/${userId}/brands/${brandId}/renewalLink`,
    method: "GET",
  });
}
