import { fromJS } from "immutable";
import { handleActions, createAction } from "redux-actions";
import { leavingSiteFrom } from "util/APIUtils";

const EMPTY_ORDER = { items: [] };
const GET_CART_SUCCESS = "app/cart/GET_CART_SUCCESS";
const SET_CART_ERROR = "app/cart/SET_CART_ERROR";
const SET_LOADING_INDICATOR = "app/cart/SET_LOADING_INDICATOR";
const RESET_ORDER = "app/cart/RESET_ORDER";

const initialState = fromJS({
  ui: {
    loading: false,
    error: "",
  },
  data: {
    order: EMPTY_ORDER,
    cart: [],
  },
});

const reducer = handleActions(
  {
    [GET_CART_SUCCESS]: (state, { payload: { order = {} } }) => {
      const cart = order.items.map((item) => item.brandId);

      return state
        .setIn(["data", "order"], fromJS(order))
        .setIn(["data", "cart"], fromJS(cart));
    },
    [SET_CART_ERROR]: (state, { payload: { error } }) => {
      return state.setIn(["ui", "error"], error);
    },
    [SET_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.setIn(["ui", "loading"], loading);
    },
    [RESET_ORDER]: () => {
      return fromJS(initialState);
    },
  },
  initialState
);

export const getCartSuccess = createAction(GET_CART_SUCCESS, (order) => ({
  order,
}));
export const setCartError = createAction(SET_CART_ERROR, (error) => ({
  error,
}));
export const setLoadingIndicator = createAction(
  SET_LOADING_INDICATOR,
  (loading) => ({ loading })
);
export const resetOrder = createAction(RESET_ORDER);

export const getCart = (fromCheckout = false, previousOrder = EMPTY_ORDER) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    dispatch(setLoadingIndicator(true));
    dispatch(setCartError(""));

    if (user && !fromCheckout) {
      try {
        const response = await createAuthClient().get(`/users/${user.id}/cart`);

        dispatch(getCartSuccess(response.data));
        dispatch(setLoadingIndicator(false));
      } catch (err) {
        dispatch(resetOrder());
        dispatch(setLoadingIndicator(false));
      }
    } else {
      dispatch(getCartSuccess(previousOrder));
      dispatch(setLoadingIndicator(false));
    }
  };
};

export const addToCart = (brandId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();

    if (user && brandId) {
      try {
        const response = await createAuthClient().post(
          `/users/${user.id}/orders`,
          { brandId }
        );

        dispatch(getCartSuccess(response.data));

        return `Brand brandNumber position was added to your cart for Renewal!`;
      } catch (err) {
        const { response } = err;
        return response
          ? response.data.message
          : "Oops! Something went wrong. Please try again!";
      }
    }
  };
};

export const checkoutCart = (location) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const { user } = state.getIn(["user", "data"]).toJS();
    const order = state.getIn(["cart", "data", "order"]).toJS();

    dispatch(setCartError(""));

    if (user) {
      try {
        const response = await createAuthClient().post(`/users/${user.id}/pay`);

        leavingSiteFrom(location, { order });
        window.location.href = response.data.checkoutUrl;
      } catch (err) {
        const { response } = err;
        dispatch(
          setCartError(
            response
              ? response.data.message
              : "There was a problem transitioning to pay."
          )
        );
      }
    } else {
      dispatch(
        setCartError(
          "A user must be supplied to this component if no controlling parent component"
        )
      );
    }
  };
};

export default reducer;
