import axios from "axios";
import { fromJS } from "immutable";
import { handleActions, createAction } from "redux-actions";
import { GENERAL_ERROR } from "common/constants";

const GET_INSPECTORS_CONTACT_LINK_SUCCESS =
  "app/help/GET_INSPECTORS_CONTACT_LINK_SUCCESS";
const SET_INSPECTORS_CONTACT_LINK_LOADING_INDICATOR =
  "app/reconcile/SET_INSPECTORS_CONTACT_LINK_LOADING_INDICATOR";
const SET_INSPECTORS_CONTACT_LINK_ERROR_MESSAGE =
  "app/reconcile/SET_INSPECTORS_CONTACT_LINK_ERROR_MESSAGE";

const initialState = fromJS({
  ui: {
    loading: false,
    error: "",
  },
  data: {
    items: [],
  },
});

// create actions
export const getInspectorsContactLinkSuccess = createAction(
  GET_INSPECTORS_CONTACT_LINK_SUCCESS,
  (items) => ({ items })
);
export const setInspectorsContactLinkLoadingIndicator = createAction(
  SET_INSPECTORS_CONTACT_LINK_LOADING_INDICATOR,
  (loading) => ({ loading })
);

export const setInspectorsContactLinkErrorMessage = createAction(
  SET_INSPECTORS_CONTACT_LINK_ERROR_MESSAGE,
  (error) => ({ error })
);

// create reducer to handle actions
const reducer = handleActions(
  {
    [GET_INSPECTORS_CONTACT_LINK_SUCCESS]: (state, { payload: { items } }) => {
      return state
        .setIn(["data", "items"], fromJS(items))
        .setIn(["ui", "loading"], false)
        .setIn(["ui", "error"], fromJS(""));
    },
    [SET_INSPECTORS_CONTACT_LINK_LOADING_INDICATOR]: (
      state,
      { payload: { loading } }
    ) => {
      return state.setIn(["ui", "loading"], loading);
    },
    [SET_INSPECTORS_CONTACT_LINK_ERROR_MESSAGE]: (
      state,
      { payload: { error } }
    ) => {
      return state.setIn(["ui", "error"], fromJS(error ?? ""));
    },
  },
  initialState
);

/*
 *  retrieve list of inspectors
 */
const getInspectorsContactLinkApiUrl = () => {
  let definedHost = (window.location.hostname ?? "").toLocaleLowerCase();

  let dataUrl;

  if (definedHost.includes("localhost")) {
    dataUrl =
      "https://localhost:7891/api/inspection/outbound/geturlsetting?settingName=BRAND_INSPECTORS_CONTACT_HELP_LINK";
  } else if (definedHost.includes("staging")) {
    dataUrl =
      "https://agbrands-inspection-api-staging.brands.utah.gov/api/inspection/outbound/geturlsetting?settingName=BRAND_INSPECTORS_CONTACT_HELP_LINK";
  } else {
    dataUrl =
      "https://agbrands-inspection-api.brands.utah.gov/api/inspection/outbound/geturlsetting?settingName=BRAND_INSPECTORS_CONTACT_HELP_LINK";
  }
  return dataUrl;
};

export const getInspectorsContactLink = () => {
  return async function (dispatch, getState) {
    //    const state = getState();

    try {
      const response = await axios.get(getInspectorsContactLinkApiUrl());
      // console.log(
      //   "in reducer fetching data from api call...",
      //   response.data.items
      // );
      dispatch(getInspectorsContactLinkSuccess(response.data.items));
      // console.log("in reducer after dispatch call ...");
    } catch (err) {
      dispatch(getInspectorsContactLinkSuccess([]));
    }
  };
};

export default reducer;
