import {
  Gesture as GestureIcon,
  ShowChart as ShowChartIcon,
  AspectRatio as AspectRatioIcon,
  Toll as TollIcon,
} from "@material-ui/icons";

const isProdBuild = process.env.NODE_ENV === "production";
const host = window.location.hostname;
// if the host domain matches brands.utah.gov or brands.localhost.com or something similar count it as prod
const isAppBaseDomain = (host.match(RegExp("\\.", "g")) || []).length === 2;
const notLocalhost = host !== "localhost";

const getEnvironment = (subdomain, envDefinedHost) => {
  return isProdBuild
    ? isAppBaseDomain
      ? `https://${subdomain}.${host}`
      : notLocalhost
      ? `https://${subdomain}-${host}`
      : envDefinedHost
    : envDefinedHost;
};
export const API_BASE_URL = getEnvironment(
  "api",
  process.env.REACT_APP_API_HOST
);
export const ADMIN_BASE_URL = getEnvironment(
  "admin",
  process.env.REACT_APP_ADMIN_HOST
);
export const AUTH_URL = getEnvironment("auth", process.env.REACT_APP_AUTH_URL);
export const INSPECTIONS_BASE_URL = getEnvironment(
  "inspect",
  process.env.REACT_APP_INSPECTIONS_HOST
);
export const RECOGNIZE_URL = getEnvironment(
  "recognize",
  process.env.REACT_APP_RECOGNIZE_HOST
);
export const IS_PROD_ENVIRONMENT = host === "brands.utah.gov";

export const APP_BASE_URL = notLocalhost
  ? `https://${host}`
  : process.env.REACT_APP_HOST_URL;

const STARTED = "STARTED";
const BRAND_APPROVED = "BRAND_APPROVED";
const OWNERSHIP_APPROVED = "OWNERSHIP_APPROVED";
const LOGIN_NEEDED = "LOGIN_NEEDED";
const LOGIN_ATTACHED = "LOGIN_ATTACHED";
const ADDED_MULTIPLE_BANDS = "ADDED_MULTIPLE_BANDS";
const RENEWAL_PERIOD_SELECTION = "RENEWAL_PERIOD_SELECTION";
const RENEWAL_PERIOD_CHANGED = "RENEWAL_PERIOD_CHANGED"; // added for renewal period selection
const CONTINUED_TO_PAY = "CONTINUED_TO_PAY";
const PRESENT_RECEIPT = "PRESENT_RECEIPT";
const RECEIPT_PRESENTED = "RECEIPT_PRESENTED";
const CANCELED_ON_PAY_SCREEN = "CANCELED_ON_PAY_SCREEN";

export const LOG_IN = "Log in";
export const SIGN_UP = "Sign up";
export const FORGOT_PASSWORD = "Forgot Password";

export const RENEWAL_STATUSES = {
  STARTED, // The user used a renewal link to start the renewal process
  BRAND_APPROVED, // User has pressed the button this is my brand
  OWNERSHIP_APPROVED, // the User has selected their name as the ownership record
  LOGIN_NEEDED, // the User has not attached an account to this renewal
  LOGIN_ATTACHED, // the User has attached an account to this renewal
  ADDED_MULTIPLE_BANDS, // the User added additional brands at renewal time
  RENEWAL_PERIOD_SELECTION, // the User given options for renewal period
  RENEWAL_PERIOD_CHANGED, // the User changed renewal period
  CANCELED_ON_PAY_SCREEN, // the user has clicked the cancel button on govpays side.
  CONTINUED_TO_PAY, // the user has clicked the pay button and transitioned to govpay
  PRESENT_RECEIPT, // the govpay payment has been captured and a receipt should be presented
  RECEIPT_PRESENTED, // the receipt has been presented to the user
};

const MOBILE = "mobile";
const HOME = "home";
const OFFICE = "office";
const FAX = "fax";
const OTHER = "other";

export const PHONE_TYPES = {
  MOBILE,
  HOME,
  OFFICE,
  FAX,
  OTHER,
};

const FACEBOOK = "FACEBOOK";
const GOOGLE = "GOOGLE";
const LOCAL = "LOCAL";

export const PROVIDERS = {
  LOCAL,
  FACEBOOK,
  GOOGLE,
  isSocial: (provider) => provider === FACEBOOK || provider === GOOGLE,
};

export const USA_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const ORDER_STATUSES = {
  CREATED: "In Cart", // just created
  CANCELED: "Canceled", // canceled by the user
  CAPTURED: "Paid Pending Review", // capture authorized through GovPay
  REVERSED: "Order Failed", // capture reversed through GovPay
  HIDDEN: "Hidden", // capture reversed through GovPay
  APPROVED: "Approved", // the order has been approved by staff
  REFUNDED: "Refunded", // the order was refunded by staff
  MANUALLY_PROCESSED: "Manually Processed", // a user paid by mail or phone and the brand inspector manually processed the payment
};

export const PRODUCTS = {
  BRAND_RENEWAL: "Renewal",
  NEW_BRAND: "Registration",
  BRAND_TRANSFER: "Transfer",
  BRAND_RENEWAL_CHEAP: "Renewal",
  BRAND_RENEWAL_FIVE_YEAR_TERM: "Renewal Five Year Term",
  BRAND_RENEWAL_FIVE_YEAR_TERM_CHEAP: "Renewal Five Year Term Cheap",
  BRAND_RENEWAL_TEN_YEAR_TERM: "Renewal Ten Year Term",
  BRAND_RENEWAL_TEN_YEAR_TERM_CHEAP: "Renewal Ten Year Term Cheap",
};

export const GENERAL_ERROR = "Oops! Something went wrong. Please try again!";

export const AUTH_PAGES = ["/auth/login", "/auth/signup", "/login", "/signup"];

export const HIDE_HEADER_FOR = [
  "/login",
  "/signup",
  "/cards",
  "/confirmreset",
  "/auth/",
];

export const SHOW_NAV_FOR = [
  "/my-brands",
  "/cart",
  "/brands/new",
  "/help",
  "/profile",
];

export const CATTLE = {
  DISTRICT_1: "D1",
  DISTRICT_2: "D2",
  DISTRICT_3: "D3",
  DISTRICT_4: "D4",
};

export const CATTLE_EARMARKS = [
  CATTLE.DISTRICT_1,
  CATTLE.DISTRICT_2,
  CATTLE.DISTRICT_3,
  CATTLE.DISTRICT_4,
];

export const LIVESTOCK_PARTS = {
  LEFT_THIGH: "LH",
  LEFT_RIB: "LR",
  LEFT_SHOULDER: "LS",
  RIGHT_THIGH: "RH",
  RIGHT_RIB: "RR",
  RIGHT_SHOULDER: "RS",
  HORSE_RIGHT_JAW: "RJ",
  WITHERS: "Wi",
  CENTER: "CB",
  RUMP: "Ru",
  SHEEP_EARMARK: "SE",
  CATTLE_EARMARK_D1: CATTLE.DISTRICT_1,
  CATTLE_EARMARK_D2: CATTLE.DISTRICT_2,
  CATTLE_EARMARK_D3: CATTLE.DISTRICT_3,
  CATTLE_EARMARK_D4: CATTLE.DISTRICT_4,
};

export const TOOLS = [
  {
    name: "Draw",
    Icon: GestureIcon,
    type: "Pencil",
  },
  {
    name: "Line",
    Icon: ShowChartIcon,
    type: "Line",
  },
  {
    name: "Rect",
    Icon: AspectRatioIcon,
    type: "Rectangle",
  },
  {
    name: "Circle",
    Icon: TollIcon,
    type: "Circle",
  },
];
